import { gsap } from 'gsap'
import { SplitText } from 'gsap/SplitText'
import { Flip } from 'gsap/Flip'
import { drawSvg } from './drawSvg'

export const headlineFade = ($el, opts = {}) => {

  const elements = $el.querySelectorAll('h1, h2, h3, h4, p')
  elements.forEach(function (element, index) {

    let text = new SplitText(element, { type: 'words' })

    element.setAttribute('aria-label', element.innerText)
    element.classList.add('gsap-split-text-target')

    gsap.timeline({
      scrollTrigger: {
        trigger: text.words,
        toggleActions: 'restart complete complete complete',
        markers: false,
        once: 1,
      },
    }).from(text.words,
      {
        delay: .2 * index,
        duration: .3,
        opacity: 0,
        ease: 'power2.in',
        y: -5,
        stagger: .05,
      }, '+=0')
  })

}

export const underlineHeadline = ($el) => 
{
  let underlineSvg = '<svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 470.08 35.42"><path fill="none" vector-effect="non-scaling-stroke" stroke="currentColor" stroke-linecap="round" stroke-width="3" d="m.38,18.35s282.03-35.69,467.11,1.76C429.14,17.91,232.17-.59,16.24,32.46"/>'

  $el.querySelectorAll('u').forEach(function (el, index){
    el.style.textDecoration = 'none'
    
    let div =  document.createElement('div')
    div.className = 'line line--underline'
    div.innerHTML = underlineSvg
    
    let underline = el.appendChild(div)

    drawSvg(underline, { duration: 1, delay: 1.5, drawSVG: '0% 0%',  ease: 'sine.out' })

  })
}

export const crossoutHeadline = ($el, crossOutWord = 'full') => 
{
  let underlineSvg = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 155 23" width="155" height="23"><path fill="none" vector-effect="non-scaling-stroke" stroke="currentColor" stroke-linecap="round" stroke-width="3" d="M3 7.3C86.9-4.6 214.3 5.5 83 9c-84.4 2.2-114.6 8.9-4.7 7.1 78.1-.2 133.3-22.6-52.9 5.3"/></svg>'
  let mark = $el.querySelector('mark')

  if(mark){
  
    mark.insertAdjacentHTML('beforebegin', '<strike class=\'crossout\'>' + crossOutWord + underlineSvg + '</strike>')
    mark.previousSibling.style.textDecoration = 'none'

    gsap.timeline({
      scrollTrigger: {
        trigger: mark.previousSibling,
        toggleActions: 'restart complete complete complete',
        markers: false,
        once: 1,
      },
    }).from(mark.previousSibling,
      {
        delay: 0.4,
        duration: .4,
        ease: 'power2.in',
        color: 'white',
      }, '+=0')

    drawSvg(mark.previousSibling, { duration: 1, delay: 0.75, drawSVG: '0% 0%',  ease: 'sine.out' })

    gsap.timeline({
      scrollTrigger: {
        trigger: mark,
        toggleActions: 'restart complete complete complete',
        markers: false,
        once: 1,
      },
    }).from(mark,
      {
        delay: 1.4,
        duration: .4,
        opacity: 0,
        ease: 'power2.in',
        right: -5,
      }, '+=0')
    
  }
}

export const markHeadline = ($el) =>
{
  let underline = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 745 39"><path fill="none" vector-effect="non-scaling-stroke" stroke="currentColor" stroke-linecap="round" stroke-width="3" d="M1.5 26.9S117.5-8 115.3 7.8c.2 8.4-62.7 49.6 64.5 6.1 72.1-20 63.2-12.6 51.5 4.4-25.1 36.4 11.7 10.2 60.9-6.5 53-24.9 148 4.4 206.1 14.8S664.2 41.2 740.4 32"/></svg>'
  let marks = $el.getElementsByTagName('mark')

  for (let mark of marks) {
    let div = document.createElement('div')
    div.className = 'line'
    div.innerHTML = underline
    let line = mark.appendChild(div)

    drawSvg(line, { duration: 1, delay: 1.5, drawSVG: '0% 0%', ease: 'sine.out' })

  }
}


export const cardFade = ($el, opts = {}) => {

  let cards = $el.querySelectorAll('.js-card')

  cards.forEach(function (card, index) {
    gsap.timeline({
      scrollTrigger: {
        trigger: card,
        toggleActions: 'restart complete complete complete',
        once: 1,
      },
    }).from(card,
      {
        delay: .1 * index,
        duration: .25,
        opacity: 0,
        ease: 'power2.in',
        y: -15,
      }, '+=0')
  })
}

export const imageLockups = ($el) => {
  let images = $el.getElementsByTagName('img')

  for (let image of images) {
    image.addEventListener('click', () => {

      console.log($el.offsetHeight)

      $el.style.height = $el.offsetHeight + 'px'

      let primary = $el.querySelectorAll('.js-imagePrimary img')[0]
      let primaryParent = primary.parentNode
      let imageParent = image.parentNode
      let state = Flip.getState($el.getElementsByTagName('img'))

      primary.classList.add('grayscale')
      image.classList.remove('grayscale')

      imageParent.appendChild(primary)
      primaryParent.appendChild(image)

      if (imageParent == primaryParent) {
        return
      }

      let timeline = Flip.from(state, {
        duration: 0.75,
        ease: 'elastic.inOut(4, 4)',
        onStart: () => {
          primaryParent.classList.remove('z-20')
          imageParent.classList.add('z-20')
        },
        onComplete: () => {
          $el.style.height = ''
        },
        onUpdate: () => {
          if ((Math.round(timeline.time() * 10) / 10) == 0.4) {
            primaryParent.classList.add('z-20')
            imageParent.classList.remove('z-20')
          }
        },
      })
    })
  }
}

import Splide from '@splidejs/splide'

export const splide = ($el) => {
  let splide = new Splide($el, {
    type: 'slide',
    start: 0,
    autoplay: false,
    pagination: false,
    arrows: false,
    width: '100%',
    autoWidth: true,
    easing: 'ease',
    gap: '2rem',
    padding: { left: '1rem', right: '2rem' },
    mediaQuery: 'min',
    keyboard: true,
    breakpoints: {
      1100: {
        padding: { left: '10vw', right: '2rem' },
      },
    },
  }).mount()

  $el.querySelector('.js-splide-next-slide').addEventListener('click', function (){
    splide.go('+1')
  })
}

import { gsap } from 'gsap'

export const desktopNavigation = ($el) => {
  let underline = '<svg xmlns="http://www.w3.org/2000/svg" class="w-full" viewBox="0 0 84 9" width="84" height="9"><path fill="none" vector-effect="non-scaling-stroke" stroke="currentColor" stroke-linecap="round" stroke-width="3" d="M3.1 5.5c9.9 0 9.2-3.4 15-3.4S23.2 7 30.9 7 39 2.2 46 2.2s6.2 3.5 11.6 3.7 4.1-1.5 10.7-2.6 12.1 1.5 13.6 1.6"/></svg>'
  
  gsap.timeline()
    .from($el.querySelectorAll('#logo'), {
      perspective: 600,
      opacity: 0,
      duration: .5,
      y: -10,
    })
    .from($el.getElementsByTagName('ul'), {
      perspective: 600,
      opacity: 0,
      duration: .55,
      stagger: .15,
      ease: 'power2.in',
      y: 2,
    })

  $el.addEventListener('mouseover', (data) => {
    $el.classList.add('nav-focused')
  })

  $el.addEventListener('mouseleave', () => {
    $el.classList.remove('nav-focused')
  })

  let links = $el.querySelectorAll('li a.js-navItem')

  for (let link of links) {

    let div = document.createElement('div')
    div.className = 'absolute bottom-0'
    div.innerHTML = underline
    let line = link.appendChild(div)
    let svg = link.getElementsByTagName('path')

    let animation = gsap.from(svg, {
      duration: .25,
      drawSVG: '0% 0%',
      ease: 'sine.out',
      paused: true,
    })

    link.addEventListener('mouseover', (ev) => {
      animation.play()
    })

    link.addEventListener('mouseleave', (ev) => {
      animation.reverse()
    })
  }
  
}

export const bndgSwoop = ($el) => {
  if (!window.rendered) {
    let tagline = $el.querySelectorAll('#tagLine')

    gsap.timeline({
      repeat: -1,
      delay: 1,
      scrollTrigger: {
        trigger: $el,
        toggleActions: 'play play play play',
        end: '150px',
      },
    }).from(tagline, {
      duration: 1.25,
      attr: { startOffset: '0%' },
      opacity: 0,
      ease: 'power1.inOut',
    }).addPause(1.45)
      .to(tagline, {
        duration: .25,
        attr: { startOffset: '75%' },
        opacity: 0,
        ease: 'power1.inOut',
      })
  }
}



import Prism from 'prismjs'
import 'prismjs/components/prism-markup-templating'
import 'prismjs/components/prism-bash'
import 'prismjs/components/prism-css'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-json'
import 'prismjs/components/prism-nginx'
import 'prismjs/components/prism-php'
import 'prismjs/components/prism-sass'
import 'prismjs/components/prism-sql'
import 'prismjs/components/prism-twig'
import 'prismjs/components/prism-typescript'
import 'prismjs/components/prism-yaml'
import 'prismjs/plugins/line-numbers/prism-line-numbers'

export const formatCode = ($el) => {
  Prism.highlightAllUnder($el)
}

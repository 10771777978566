import { gsap } from 'gsap'

export const drawSvg = ($el, opts = {}) => {
  let svg = $el.getElementsByTagName('path')
  let defaultOpts = { duration: 1.5, stagger: 0.1, drawSVG: '0% 0%', ease: 'sine.out' }
  let finalOpts = { ...defaultOpts, ...opts }

  gsap.timeline({
    scrollTrigger: {
      trigger: $el,
      toggleActions: 'restart complete complete complete',
      markers: false,
      ease: 'circ.inOut',
    },
  }).from(svg, finalOpts)
}

export const drawFromMiddleSvg = ($el, opts = {}) => {
  let svg = $el.getElementsByTagName('path')
  let defaultOpts = { duration: .75, opacity: 0, stagger: 0.1, drawSVG: '50% 50%', ease: 'sine.out' }
  let finalOpts = { ...defaultOpts, ...opts }

  gsap.timeline({
    scrollTrigger: {
      trigger: $el,
      toggleActions: 'restart complete complete complete',
      markers: false,
      ease: 'circ.inOut',
    },
  }).from(svg, finalOpts)
}

import noUiSlider from 'nouislider'
import wNumb from 'wnumb'

let defaults = {

  start: [120, 350],
  range: {
    min: 15,
    max: 500,
  },
  step: 15,

  tooltips: [
    true,
    wNumb({
      decimals: 0,
      thousand: ',',
      suffix: 'k',
      prefix: '$',
    }),
  ],

  connect: true,

  pips: {
    mode: 'range',
    density: 10,
    format: wNumb({
      decimals: 0,
      thousand: ',',
      suffix: 'k',
      prefix: '$',
    }),
  },

  format: wNumb({
    decimals: 0,
    thousand: ',',
    suffix: 'k',
    prefix: '$',
  }),
}

export const moneyRangeSlider = ($el, opts) => {

  const settings = {
    ...defaults,
    ...opts,
  }

  noUiSlider.create($el, settings)

  const $els = {
    display: {
      min: document.querySelector('[data-min-budget-display]'),
      max: document.querySelector('[data-max-budget-display]'),
    },
    data: {
      min: document.querySelector('input[name="minBudget"]'),
      max: document.querySelector('input[name="maxBudget"]'),
    },
  }

  $el.noUiSlider.on('update', (values) => {
    const [min, max] = values

    $els.display.min.innerHTML = min
    $els.display.max.innerHTML = max

    $els.data.min.value = min
    $els.data.max.value = max
  })
}

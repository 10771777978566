import 'virtual:svg-icons-register'
import.meta.glob('../img/**/*')

import Swup from 'swup'
import SwupHeadPlugin from '@swup/head-plugin'
import SwupA11yPlugin from '@swup/a11y-plugin'
import SwupProgressPlugin from '@swup/progress-plugin'

import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import intersect from '@alpinejs/intersect'
import collapse from '@alpinejs/collapse'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
import { Flip } from 'gsap/Flip'

gsap.registerPlugin(DrawSVGPlugin)
gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(Flip)

import { moneyRangeSlider } from './rangeSliders'
import { bndgSwoop, desktopNavigation } from './header'
import { drawSvg, drawFromMiddleSvg } from './drawSvg'
import { splide } from './splide'
import { headlineFade, cardFade, markHeadline, underlineHeadline, imageLockups, crossoutHeadline } from './contentAnimations'
import { formatCode } from './prism'
import { freeformCsrf } from './freeform'

export function init(config = {}) {
  window.zaengle = {
    underlineHeadline,
    splide,
    moneyRangeSlider,
    markHeadline,
    imageLockups,
    headlineFade,
    freeformCsrf,
    formatCode,
    drawSvg,
    drawFromMiddleSvg,
    desktopNavigation,
    crossoutHeadline,
    cardFade,
    bndgSwoop,
  }

  // @see https://swup.js.org/plugins/
  const swupPlugins = [
    new SwupProgressPlugin(),
    new SwupHeadPlugin(),
    new SwupA11yPlugin(),
  ]

  // Page transition manager
  const swup = new Swup({
    containers: ['#dynamic'],
    plugins: swupPlugins,
  })

  window.Alpine = Alpine

  Alpine.plugin(focus)
  Alpine.plugin(intersect)
  Alpine.plugin(collapse)

  Alpine.store('changing', false)
  Alpine.store('mobileNav', {
    open: false,
    toggle() {
      this.open = !this.open
    },
    close() {
      this.open = false
    },
  })

  Alpine.start()

  document.addEventListener('alpine:init', () => {
    Alpine.store('changing', false)
  })

  swup.hooks.before('content:replace', (visit) => {
    Alpine.store('changing', true)
    Alpine.store('mobileNav').open = false

    window.rendered = true
 })
}

window.addEventListener('DOMContentLoaded', () => init(window?.CONFIG || {}))

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('HMR')
  })
}
export const freeformCsrf = async ($el) => {
  const form = $el.querySelector('form')
  
  try {
    const paramNameResponse = await fetch('/actions/blitz/csrf/param')
    const paramName = await paramNameResponse.text()
  
    const tokenResponse = await fetch('/actions/blitz/csrf/token')
    const token = await tokenResponse.text()
  
    const csrfInput = form.querySelector(`input[name="${paramName}"]`)
    csrfInput.value = token
  
    //console.log(paramName, token)
  } catch (error) {
    console.error('An error occurred:', error)
  }
}